import React from "react"
import "../components/site_map/SiteMap"
import SiteMap from "../components/site_map/SiteMap"
import { Helmet } from "react-helmet"
import Footer from '../components/footer/Footer'
import ISI from '../components/isi/ISI'

import Loader from "../components/loader"


export default (props) => {
    return (
      <Loader>
      <div className="sitemap">
	      <Helmet>
	         <meta charSet="utf-8" />
	         <title>sitemap</title>
	         <link rel="canonical" href="https://www.vascepahcp.com/sitemap"/>
	       </Helmet>
         <SiteMap/>
         <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
         <Footer/>
      </div>
      </Loader>
    )
}
