import React from "react"
import "./SiteMap.css"
import Layout from "../../components/layout"
import Hero from '../hero/Hero'
import { Link } from "gatsby"
import header from "../../components/header/Header"

export default class SiteMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  goModal = (str) => {
    // console.log('clicked')
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: str }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  handleClick(e) {
    // console.log('clicked')
  }


    render() {

      return (
        <Layout>
          <Hero bckgrdImage={"landingimage"}>
            <div>
              <h2 className="headline">
                <span className="bold700">Site map</span>
              </h2>
            </div>
          </Hero>

          <div className="grid-container site-map">
            <div className="item1">
              <h2>CV Outcomes</h2>
              <Link to="/the-vascepa-patient/">
                <h4 style={{ fontWeight: "100" }}>The VASCEPA Patient</h4>
              </Link>
              <Link to="/outcomes-data/">
                <h4 style={{ fontWeight: "100" }}>CV Outcomes Data</h4>
              </Link>
              <Link to="/total-events-analysis/">
                <h4 style={{ fontWeight: "100" }}>Total Events Analysis</h4>
              </Link>
              <Link to="/coronary-revascularization-data/">
                <h4 style={{ fontWeight: "100" }}>
                  Coronary Revascularization Analysis
                </h4>
              </Link>
              <Link to="/prior-mi-subgroup-data/">
                <h4 style={{ fontWeight: "100" }}>Prior MI Subgroup Data</h4>
              </Link>
              <Link to="/safety/">
                <h4 style={{ fontWeight: "100" }}>Safety & Side Effects</h4>
              </Link>
            </div>
            <div className="item2">
              <h2>Why VASCEPA?</h2>
              <Link to="/vascepa-difference/">
                <h4 style={{ fontWeight: "100" }}>The VASCEPA Difference</h4>
              </Link>
              <Link to="/guidelines/">
                <h4 style={{ fontWeight: "100" }}>Guidelines</h4>
              </Link>
            </div>
            <div className="item3">
              <h2>Getting Patients Started</h2>
              <Link to="/dosing/">
                <h4 style={{ fontWeight: "100" }}>Dosing</h4>
              </Link>
              <Link to="/get-vascepa-now/">
                <h4 style={{ fontWeight: "100" }}>Get VASCEPA Now</h4>
              </Link>
              <Link to="/access-and-coverage/">
                <h4 style={{ fontWeight: "100" }}>Access & Coverage</h4>
              </Link>
            </div>
            <div className="">
              <h2>Helpful Resources</h2>
              <Link to="/resources/hcps/">
                <h4 style={{ fontWeight: "100" }}>HCP Resources</h4>
              </Link>
              <Link to="/resources/pharmacists/">
                <h4 style={{ fontWeight: "100" }}>Pharmacist Resources</h4>
              </Link>
              <Link to="/patient-resources/">
                <h4 style={{ fontWeight: "100" }}>Patient Resources</h4>
              </Link>
            </div>
          </div>
        </Layout>
      )
  }
}
